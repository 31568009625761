.img-size {
  border-radius: 10px;
  width: 80%;
}

@media screen and (max-width: 991px) {
  .img-size {
    width: 60%;
  }
}
